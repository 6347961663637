import { inject, Injectable } from '@angular/core';
import { CrmModalService } from 'common-module/modal';
import { CrmUser, CrmUserService } from 'common-module/user';
import { Observable, tap } from 'rxjs';

import { CheckEmailParams } from '~/api/user/check-email-params';
import { RegisterUserDto } from '~/api/user/register-user-dto';
import { getCurrentDomain } from '~/shared/utils/common/get-current-domain';

@Injectable({ providedIn: 'root' })
export class UserService extends CrmUserService {
  protected readonly modalService = inject(CrmModalService);

  override login<Body>(body: Body, options?: object): Observable<CrmUser> {
    return super.login({ ...body, domain: getCurrentDomain() }, options);
  }

  override logout(options?: object): Observable<string> {
    return super.logout(options).pipe(tap(() => this.modalService.closeAll()));
  }

  registration(body: RegisterUserDto): Observable<CrmUser> {
    const updatedBody: RegisterUserDto & { domain: string } = {
      ...body,
      domain: getCurrentDomain(),
    };

    return this.endpoint.request<CrmUser>('POST', 'registration', {
      body: updatedBody,
    });
  }

  checkEmail(params: CheckEmailParams): Observable<{ available: boolean }> {
    const updatedParams: CheckEmailParams & { domain: string } = {
      ...params,
      domain: getCurrentDomain(),
    };

    return this.endpoint.request<{ available: boolean }>(
      'GET',
      'registration',
      { params: updatedParams },
    );
  }

  override resetPassword<Body>(body: Body): Observable<CrmUser> {
    return super.resetPassword({ ...body, domain: getCurrentDomain() });
  }
}
