import { inject, Injectable } from '@angular/core';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { map, Observable, of, switchMap, tap } from 'rxjs';

import { getCurrentDomain } from '~/shared/utils/common/get-current-domain';

import { DocumentsApiService } from '../documents/documents-api.service';
import { getResourceUrl } from '../documents/get-resource-url';

import { ClinicModel } from './clinic.model';

@Injectable({ providedIn: 'root' })
export class ClinicsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'clinics',
  })
  protected readonly endpoint!: CrmEndpoint<ClinicModel>;

  private documentsApiService = inject(DocumentsApiService);

  private profile?: ClinicModel;

  getProfile(): Observable<ClinicModel> {
    if (this.profile) {
      return of(this.profile);
    }

    return this.endpoint
      .request<ClinicModel>('GET', 'profile', {
        params: { domain: getCurrentDomain() },
      })
      .pipe(tap((profile) => (this.profile = profile)));
  }

  getProfileAndLogo(): Observable<{ profile: ClinicModel; logoUrl?: string }> {
    return this.getProfile().pipe(
      switchMap((profile) => {
        if (!profile.logo) {
          return of({ profile, logoUrl: undefined });
        }

        return this.documentsApiService.checkResource(profile.logo).pipe(
          map((id) => ({
            profile,
            logoUrl: id ? getResourceUrl(id) : undefined,
          })),
        );
      }),
    );
  }
}
