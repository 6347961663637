import { CrmEndpointModuleConfig } from 'common-module/endpoint';

import { environment } from '~/environments/environment';

export const endpointConfig: CrmEndpointModuleConfig = {
  endpoints: [
    {
      name: 'crm',
      baseUrl: environment.apiUrl,
      withCredentials: true,
      httpHeaders: { Authorization: `Basic ${environment.basicAuth}` },
    },
  ],
};
