import { APP_PROJECT_NAME, APP_VERSION } from './variables';
import { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  env: 'development',
  version: APP_VERSION,
  projectName: APP_PROJECT_NAME,
  apiUrl: 'https://client-api.docut.touch4dev.net',
  basicAuth: 'ZG9jdXQ6VDREY2w1YWRBUTRKdXpmZ3pQcnE=',
};
